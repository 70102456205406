
/* For pdf */
.react-pdf__Page {
  z-index: 1;
  width: 100%;
  height: 100%;
  /*Optional*/
  position: absolute !important;
  top: 0;
  left: 0;
  /*Optional*/
}
.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

  /*Optional*/
.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}
/*Optional*/

  /*Optional*/
.react-pdf__Page__canvas {
  margin: 0 auto;
}
/*Optional*/

/*Optional*/
/* For all pages */
.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}
/*Optional*/

#drawArea {
  width: 612px;
  height: 792px;
  border: 1px solid black;
  /*PROBLEMATIC!!!!*/
  /* margin-left: 30%; */
  position: relative;
}

.crosshair {
  cursor: crosshair;
}

/* svg {
  z-index: 1;
  position: absolute !important;
  top: 0;
  left: 0;
} */

.path {
  fill: none;
  stroke-width: 1px;
  stroke: red;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.drawing {
  width: 100%;
  height: 100%;
}
